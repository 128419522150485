import { UIFilterAssignments } from '@/store/recipientJourney/types';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APIChecklistOutcomeInterface, APIChecklist, 
  APIChecklistGroup, APIChecklistTaskInterface, APIChecklistCounts } from '@/APIModels/journey/types';
import { UIRecipient } from '@/UIModels/recipient';
import { UIJourney } from '../journey';
import i18n from '@/i18n';
import { SaveResult, APIRules, APINewResonse, APIShowResponse, 
  APISaveChecklistOutcomeResponse, APIShowChecklistOutcomeData } from '@/types';
import { APIRoute, EP } from '@/api-endpoints';
import axios from 'axios';
import vuexStore from '@/store'; // for gradual conversion, see fullUserDetails

export class UIChecklistOutcome {
  public apiSource: APIChecklistOutcomeInterface|null = null;
  public id: string|null = null;
  public loaded = false;

  public service_date?: string|null = null;
  public expiration_date?: string|null = null;
  public imaging_received?: string|null = null;
  public outcome?: string|null = null;

  public permitted_actions: string[] = [];

  // Define new UI view model structure
  public constructor(apiChecklistOutcome: APIChecklistOutcomeInterface|null = null) {
    if (apiChecklistOutcome) this.updateFromAPIResponse(apiChecklistOutcome);
  }

  // Load resource data and permitted actions
  public async load(opts: { uiJourney: UIJourney, task: UIChecklistTask, id: string }): Promise<void> {
    if (!opts.uiJourney) return;
    if (!opts.task) return;
    if (opts.id) {
      await this.loadShow(opts);
    } else {
      await this.loadNew(opts);
    }
  }

  // Load list for specified checklist outcome
  public static async loadFor(uiJourney: UIJourney, task: UIChecklistTask): Promise<UIChecklistOutcome[]> {
    const clientId = uiJourney.clientId || '';
    const journeyId = uiJourney.journeyId || '';
    const checklist_id = task.checklist_id || null;
    const task_id = task.id || null;

    try {
      let result: UIChecklistOutcome[] = [];
      const url = APIRoute(EP.recipients.journeys.checklists.outcomes.index, [[':recipient_id', (clientId as string)], [':journey_id', (journeyId as string)], [':checklist_id', checklist_id as string], [':task_id', task_id as string]]);
      await axios.get(url).then((response: any) => {
        const apChecklistOutcomeInterface: APIChecklistOutcomeInterface[] = response?.data?.outcomes || [];
        result = apChecklistOutcomeInterface.map((item: APIChecklistOutcomeInterface): UIChecklistOutcome => {
          return new UIChecklistOutcome(item);
        });
      });
      return result;
    } catch (errorResponse: unknown) {
      console.warn((new UIError('checklist_outcomes', errorResponse)).errorResult);
      throw new UIError('checklist_outcomes');
    }
  }

  private async loadNew(opts: { uiJourney: UIJourney, task: UIChecklistTask }): Promise<void> {
    const checklist_id = opts.task.checklist_id || null;
    const task_id = opts.task.id || null;
    const url = APIRoute(EP.recipients.journeys.checklists.outcomes.new, [[':recipient_id', (opts.uiJourney.clientId as string)], [':journey_id', (opts.uiJourney.journeyId as string)], [':checklist_id', checklist_id as string]]);
    try {
      const response: APINewResonse = await axios.get(url);
      this.permitted_actions = response.data.permitted_actions;
      this.setRules(response.data.rules, opts.task);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
    }
  }

  private async loadShow(opts: { uiJourney: UIJourney, task: UIChecklistTask, id: string }): Promise<void> {
    const checklist_id = opts.task.checklist_id || null;
    const task_id = opts.task.id || null;
    const url = APIRoute(EP.recipients.journeys.checklists.outcomes.show, [[':recipient_id', (opts.uiJourney.clientId as string)], [':journey_id', (opts.uiJourney.journeyId as string)], [':checklist_id', checklist_id as string], [':task_id', task_id as string], [':id', (opts.id)]]);
    try {
      const response: APIShowResponse<APIShowChecklistOutcomeData> = await axios.get(url);
      const apiOutcome: APIChecklistOutcomeInterface = response.data.outcome;
      this.permitted_actions = response.data.permitted_actions;
      this.setRules(response.data.rules, opts.task);
      this.updateFromAPIResponse(apiOutcome);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
    }
  }

  private setRules(rules: APIRules, task: UIChecklistTask): void {
    vuexStore.commit('validations/resetPrefix', `checklist_outcomes_${task.id}`);
    vuexStore.commit('validations/set', { rules: { [`checklist_outcomes_${task.id}`]: rules } });
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiChecklistOutcome: APIChecklistOutcomeInterface) {
    this.apiSource = apiChecklistOutcome;

    this.id = apiChecklistOutcome._id?.$oid || null;
    this.service_date = apiChecklistOutcome.service_date || null;
    this.expiration_date = apiChecklistOutcome.expiration_date || null;
    this.imaging_received = apiChecklistOutcome.imaging_received || null;
    this.outcome = apiChecklistOutcome.outcome || null;

    this.loaded = true;
  }

  // Build a copy of specific details for the purpose of tracking unsaved changes
  // NOTE: this is intended to be the view model safe to use for input v-models
  public copyViewModel() {
    const apiSource = this.apiSource as APIChecklistOutcomeInterface;
    if (!apiSource) return new UIChecklistOutcome();

    return new UIChecklistOutcome(apiSource);
  }

  // Is this an unsaved New Outcome?
  get isNew(): boolean {
    return this.apiSource === null;
  }

  // Generate request payload parameters to provide to API for a Referral Attributes patch
  public extractPatch(): APIChecklistOutcomeInterface {
    const result: APIChecklistOutcomeInterface = {
      id: this.id || null,
      service_date: this.service_date || null,
      expiration_date: this.expiration_date || null,
      imaging_received: this.imaging_received || null,
      outcome: this.outcome || null,
    };

    return result;
  }

  public setPermittedActions(permitted_actions: string[]) {
    this.permitted_actions = permitted_actions;
  }

  public delete(opts: { selected: UIChecklistOutcome, recipient: UIRecipient, journey: UIJourney, task: UIChecklistTask }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {

      const selectedId = opts.selected.id;
      const recipientId = opts.recipient.clientId;
      const journeyId = opts.journey.journeyId;
      const checklistId = opts.task.checklist_id;
      const taskId =  opts.task.id;

      // check for blank content
      if (!selectedId) reject((new UIError('checklist_outcomes')));
      if (!recipientId) reject((new UIError('checklist_outcomes')));
      if (!journeyId) reject((new UIError('checklist_outcomes')));
      if (!checklistId) reject((new UIError('checklist_outcomes')));
      if (!taskId) reject((new UIError('checklist_outcomes')));

      const ep = APIRoute(EP.recipients.journeys.checklists.outcomes.update, [[':recipient_id', recipientId as string], [':journey_id', journeyId as string], [':checklist_id', checklistId as string], [':task_id', taskId as string], [':id', selectedId as string]]);
      axios.delete(ep).then((response: APISaveChecklistOutcomeResponse) => {
        if (response.data.errors) {
          reject((new UIError('checklist_outcomes', response)).errorResult);
        } else {
          resolve((new UISuccess(response)).getSaveResult());
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('checklist_outcomes', errorResponse)).errorResult);
      });
    });
  }
}

export class UIChecklistTask {
  public apiSource: APIChecklistTaskInterface|null = null;

  public id: string|null = null;
  public checklist_id: string|null = null;
  public group_id: string|null = null;

  public assigned_user_id: string|null = null;
  public completed = false;
  public completed_by_user_id: string|null = null;
  public completed_date: string|null = null;
  public completed_timestamp: string|null = null;
  public external = false;
  public name: string|null = null;
  public sortRank: string|null = null;
  public status: string|null = null;
  public status_class: string|null = null;
  public updated_at: string|null = null;
  public updated_by: string|null = null;

  public currentOutcome: UIChecklistOutcome|null = null;
  public outcomes: UIChecklistOutcome[] = [];

  public active = false;
  public isNew = false;

  public permitted_actions: string[] = [];
  public loaded = false;
  public showOutcomeForm = false;

  // Define new UI view model structure
  public constructor(apiChecklistTask: APIChecklistTaskInterface|null = null) {
    this.active = true;

    if (apiChecklistTask) this.updateFromAPIResponse(apiChecklistTask);

    if (!apiChecklistTask) {
      this.isNew = true;
      // make unique id for 'new' task
      // convert . in 1718909625675.1636 to - so validation is not broken 
      this.id = ((Date.now() + Math.random()).toString()).replace('.', '-');
    }
  }

  // Task construter using API checklist task as base
  public async updateFromAPIResponse(apiChecklistTask: APIChecklistTaskInterface): Promise<void> {
    this.apiSource = apiChecklistTask;
    this.id = apiChecklistTask._id?.$oid || null;
    this.assigned_user_id = apiChecklistTask.assigned_user_id || null;
    this.checklist_id = apiChecklistTask.checklist_id?.$oid || null;
    this.group_id = apiChecklistTask.group_id?.$oid || null;

    this.completed_by_user_id = apiChecklistTask.completed_by_user_id || null;
    this.completed_date = apiChecklistTask.completed_date || null;
    this.completed_timestamp = apiChecklistTask.completed_timestamp || null;
    
    this.completed = apiChecklistTask.completed_by_user_id ? true : false;

    this.external = apiChecklistTask.external || false;

    this.name = apiChecklistTask.name || null;
    this.sortRank = apiChecklistTask.sort_rank || null;
    this.status = apiChecklistTask.status || null;
    this.status_class = apiChecklistTask.status_class || null;
    this.updated_at = apiChecklistTask.updated_at || null;
    this.updated_by = apiChecklistTask.updated_by || null;
    this.permitted_actions = apiChecklistTask.permitted_actions || [];

    this.currentOutcome = new UIChecklistOutcome(apiChecklistTask.current_outcome);

    if (apiChecklistTask.outcomes) {
      this.outcomes = apiChecklistTask.outcomes.map((apiOutcome: APIChecklistOutcomeInterface) => {
        const uiOutcome = new UIChecklistOutcome(apiOutcome);
        return uiOutcome;
      });
    }

    this.active = false;
  }

  public replaceWithAPIChecklistTask(apiChecklistTask: APIChecklistTaskInterface) {
    this.apiSource = apiChecklistTask;
    this.id = apiChecklistTask._id?.$oid || null;
    this.assigned_user_id = apiChecklistTask.assigned_user_id || null;
    this.checklist_id = apiChecklistTask.checklist_id?.$oid || null;
    this.group_id = apiChecklistTask.group_id?.$oid || null;

    this.completed_by_user_id = apiChecklistTask.completed_by_user_id || null;
    this.completed_date = apiChecklistTask.completed_date || null;
    this.completed_timestamp = apiChecklistTask.completed_timestamp || null;
    
    this.completed = apiChecklistTask.completed_by_user_id ? true : false;

    this.external = apiChecklistTask.external || false;

    this.name = apiChecklistTask.name || null;
    this.sortRank = apiChecklistTask.sort_rank || null;
    this.status = apiChecklistTask.status || null;
    this.status_class = apiChecklistTask.status_class || null;
    this.updated_at = apiChecklistTask.updated_at || null;
    this.updated_by = apiChecklistTask.updated_by || null;
    this.permitted_actions = apiChecklistTask.permitted_actions || [];

    this.currentOutcome = new UIChecklistOutcome(apiChecklistTask.current_outcome);

    if (apiChecklistTask.outcomes) {
      this.outcomes = apiChecklistTask.outcomes.map((apiOutcome: APIChecklistOutcomeInterface) => {
        const uiOutcome = new UIChecklistOutcome(apiOutcome);
        return uiOutcome;
      });
    }

    this.active = true;
  }

  // Returns UI class that relates to task status
  public get getStatusClass(): string {
    switch (this.status) {
      case 'complete': 
        return 'complete';
        break;
      case 'new':
        return 'new';
        break;
      default:
        return 'in-progress';
        break;
    }
  }

  // Returns task completed styling class name
  public get getCompletedStyle(): string {
    return this.completed ? 'd-flex task-completed' : 'd-flex';
  }

  // Returns task completed styling class name
  public get getSelectedStyle(): string {
    return this.active ? 'd-flex task-selected' : 'd-flex';
  }

  // Method to flag when a task is expanded or closed
  public toggle(): void {
    if (!this.active) { this.active = false; }
    this.active = !this.active;
  }

  public replaceSource(apiTask: APIChecklistTaskInterface): any {
    this.apiSource = apiTask;
    this.name = apiTask.name as any;
  }

  // Build a copy of the checklist task for the purpose of tracking unsaved changes
  // NOTE: this is intended to be the view model safe to use for input v-models
  public copyViewModel(): UIChecklistTask {
    const apiSource = this.apiSource as APIChecklistTaskInterface;
    if (!apiSource) return new UIChecklistTask();

    return new UIChecklistTask(apiSource);
  }

  // check if task would be hidden by checklist filters
  public isTaskHiddenByFilters(filters: UIFilterAssignments): boolean {
    let response = false;
    // if status does not match filter status, return true, task will be hidden
    if (this.status !== filters.checklist_status && filters.checklist_status !== null) { response = true; }
    // if task not completed and filter excluding completed, return true, task will be hidden
    if (this.completed && filters.exclude_completed) { response = true; }
    return response;
  }

  public get getTaskReferenceId(): string {
    return `checklist-task-${this.id}`;
  }

  // Make task save request
  public save(opts: { selected: any, recipient: UIRecipient, journey: UIJourney, outcome: UIChecklistOutcome }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId || null;
      const journeyId = opts.journey.journeyId || null;
      const checklistId = opts.selected.checklist_id;

      if (!recipientId) reject((new UIError('checklist_error')));
      if (!journeyId) reject((new UIError('checklist_error')));
      if (!checklistId) reject((new UIError('checklist_error')));

      const taskId = this.id || null;
      const outcomeId = opts.outcome ? opts.outcome.id : null;

      let method: any;
      let ep: string;
      let payload: any = null;

      // send outcome and task
      if (opts.outcome) {
        if (outcomeId) {
          method = axios.patch;
          ep = APIRoute(EP.recipients.journeys.checklists.outcomes.update, [[':recipient_id', recipientId as string], [':journey_id', journeyId as string], [':checklist_id', checklistId as string], [':task_id', taskId as string], [':id', outcomeId]]);
        } else {
          if (taskId && !this.isNew) {
            method = axios.post;
            ep = APIRoute(EP.recipients.journeys.checklists.outcomes.create, [[':recipient_id', recipientId as string], [':journey_id', journeyId as string], [':checklist_id', checklistId as string], [':task_id', taskId as string]]);  
          } else {
            method = axios.post;
            ep = APIRoute(EP.recipients.journeys.checklists.outcomes.newTaskAndOutcomes, [[':recipient_id', recipientId as string], [':journey_id', journeyId as string], [':checklist_id', checklistId as string]]);  
          }
        }
        payload = { outcome: opts.outcome.extractPatch() };
        payload.outcome.checklist_task = this.extractPatch();

      // send only task
      } else {
        if (taskId && !this.isNew) {
          method = axios.patch;
          ep = APIRoute(EP.recipients.journeys.checklists.tasks.update, [[':recipient_id', recipientId as string], [':journey_id', journeyId as string], [':checklist_id', checklistId as string], [':id', taskId]]);
        } else {
          method = axios.post;
          ep = APIRoute(EP.recipients.journeys.checklists.tasks.create, [[':recipient_id', recipientId as string], [':journey_id', journeyId as string], [':checklist_id', checklistId as string]]);
        }
        payload = { task: this.extractPatch() };
      }

      method(ep, payload).then((response: any) => {
        if (response.data.errors) {
          reject((new UIError(`checklist_${taskId}`, response)).errorResult);
        } else {
          // build temporary object for task reload
          const taskForReload = response.data.task ? new UIChecklistTask(response.data.task) : new UIChecklistTask({
            _id: response.data.outcome.checklist_task_id,
            checklist_id: { $oid: checklistId }
          });
          this.loadTask({ recipient: opts.recipient, journey: opts.journey, task: taskForReload }).then((loadResponse: any) => {
            // send response, need new task along with checklist and group counts
            const newResponse: any = {
              data: loadResponse
            };
            resolve((new UISuccess(newResponse)).getSaveResult());
          }).catch((errorResponse: any) => {
            reject((new UIError(`checklist_${taskId}`, 'An error occured')).errorResult);
          });
        }
      }).catch((errorResponse: any) => {
        reject((new UIError(`checklist_${taskId}`, errorResponse)).errorResult);
      });
    });
  }

  private async loadTask(opts: { recipient: UIRecipient, journey: UIJourney, task: UIChecklistTask }): Promise<any> {
    const recipientId = opts.recipient.clientId;
    const journeyId = opts.journey.journeyId;
    const checklist_id = opts.task.checklist_id || null;
    const taskId = opts.task.id || null;

    const url = APIRoute(EP.recipients.journeys.checklists.tasks.show, [[':recipient_id', recipientId as string], [':journey_id', journeyId as string], [':checklist_id', checklist_id as string], [':id', taskId as string]]);
    try {
      const response: any = await axios.get(url);
      const apiResponse: any = response.data;
      this.loaded = true;
      return apiResponse;
    } catch (errorResponse: unknown) {
      this.loaded = true;
      throw new UIError(`checklist_${taskId}`, errorResponse);
    }
  }

  public async loadOutcomes(opts: { recipient: UIRecipient, journey: UIJourney, task: UIChecklistTask }): Promise<void> {
    const recipientId = opts.recipient.clientId;
    const journeyId = opts.journey.journeyId;
    const checklist_id = opts.task.checklist_id || null;
    const task_id = opts.task.id || null;
    
    const url = APIRoute(EP.recipients.journeys.checklists.outcomes.index, [[':recipient_id', recipientId as string], [':journey_id', journeyId as string], [':checklist_id', checklist_id as string], [':task_id', task_id as string]]);
    try {
      // reload outcomes index
      const response: any = await axios.get(url);
      const apiOutcomes: APIChecklistOutcomeInterface[] = response.data.outcomes;
      if (apiOutcomes) {
        this.outcomes = apiOutcomes.map((apiOutcome: APIChecklistOutcomeInterface) => {
          const uiOutcome = new UIChecklistOutcome(apiOutcome);
          return uiOutcome;
        });
      }
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
    }
  }

  // Generate request payload parameters to provide to API as part of Create or Update activity
  private extractPatch(): APIChecklistTaskInterface {
    const result: APIChecklistTaskInterface = {
      name: this.name,
      external: this.external || null,
      status: this.status || null,
    };

    if (this.checklist_id) { result.checklist_id = { $oid: this.checklist_id }; }
    if (this.group_id) { result.group_id = { $oid: this.group_id }; }

    // don't send id if new
    if (!this.isNew) {
      if (this.id) { result._id = { $oid: this.id }; }
    }

    return result;
  }
}

export class UIChecklistGroup {
  public apiSource: APIChecklistGroup|null = null;

  public id: string|null = null;

  public name: string|null = null;
  public sortRank: number|null = null;
  public updated_at: string|null = null;
  public updated_by: string|null = null;
  public checklist_task_completed_count: string|null = null;
  public checklist_task_completed_fraction: string|null = null;
  public checklist_task_count: string|null = null;

  public tasks: UIChecklistTask[] = [];

  public active = false;
  public showNew = false;
  public showHiddenMessage: string|null; // text for show hidden message
  public showHiddenMessageNear: string|null; // id hidden message be shown near

  public newTask: UIChecklistTask = new UIChecklistTask();

  // Is this an unsaved New Group?
  get isNew(): boolean {
    return !this.id;
  }

  // Define new UI view model structure
  public constructor(apiChecklistGroup: APIChecklistGroup|null = null) {
    this.active = true;
    this.showHiddenMessage = null;
    this.showHiddenMessageNear = null;

    if (apiChecklistGroup) this.updateFromAPIResponse(apiChecklistGroup);
  }

  // Class constructor using api group as base
  public updateFromAPIResponse(apiChecklistGroup: APIChecklistGroup) {
    this.apiSource = apiChecklistGroup;

    this.id = apiChecklistGroup._id?.$oid || null;
    this.name = apiChecklistGroup.name || null;
    this.sortRank = apiChecklistGroup.sort_rank || null;
    this.updated_at = apiChecklistGroup.updated_at || null;
    this.updated_by = apiChecklistGroup.updated_by || null;
    this.checklist_task_completed_count = apiChecklistGroup.checklist_task_completed_count || null;
    this.checklist_task_completed_fraction = apiChecklistGroup.checklist_task_completed_fraction || null;
    this.checklist_task_count = apiChecklistGroup.checklist_task_count || null;

    if (apiChecklistGroup.tasks) {
      this.tasks = apiChecklistGroup.tasks.map((apiTask: APIChecklistTaskInterface) => {
        if (!apiTask) return new UIChecklistTask();

        const uiTask = new UIChecklistTask(apiTask);
        return uiTask;
      });
    }
  }

  // Method to return number of tasks in a group
  public get getNumberOfTasks(): number {
    return this.tasks.length;
  }

  // Method to return number of completed tasks in a group
  public get getNumberOfCompletedTasks(): number {
    const completed = this.tasks.filter((task: UIChecklistTask) => { 
      return task.completed === true;
    });
    return completed.length;
  }

  // Build a copy of the checklist group for the purpose of tracking unsaved changes
  // NOTE: this is intended to be the view model safe to use for input v-models
  public copyViewModel(): UIChecklistGroup {
    const apiSource = this.apiSource as APIChecklistGroup;
    if (!apiSource) return new UIChecklistGroup();

    return new UIChecklistGroup(apiSource);
  }  

  // find the task that closely matches the original task's sort rank
  public findClosestMatch(originalTask: UIChecklistTask): UIChecklistTask|null {
    // find a task with a similar sortRank
    let match: UIChecklistTask|null = null;
    this.tasks.map((task: UIChecklistTask) => {
      // if the sort rank isn't higher than the original copy it
      if (task.sortRank && originalTask.sortRank && task.sortRank <= originalTask.sortRank) {
        match = task;
      }
    });
    return match ? match : null;
  }

  // clears the show hidden message from view
  // should be done when a new task is created
  public resetShowHiddenMessage(): void {
    this.showHiddenMessage = null;
    this.showHiddenMessageNear = null;
  }

  // find task by id
  public findTaskById(taskId: string|null): UIChecklistTask|null {
    if (!taskId) return null;
    const found = this.tasks.find((task: UIChecklistTask) => {
      return task.id === taskId;
    });
    return found ? found : null;
  }

  public updateCounts(counts: APIChecklistCounts) {
    this.checklist_task_completed_count = counts.checklist_task_completed_count || null;
    this.checklist_task_completed_fraction = counts.checklist_task_completed_fraction || null;
    this.checklist_task_count = counts.checklist_task_count || null;
  }
}

export class UIChecklist {
  public apiSource: APIChecklist|null = null;

  public id: string|null = null;
  public name: string|null = null;
  public permitted_actions: string[] = [];
  public sortRank: number|null = 0;
  public updated_at: string|null = null;
  public updated_by: string|null = null;
  public checklist_task_completed_count: string|null = null;
  public checklist_task_completed_fraction: string|null = null;
  public checklist_task_count: string|null = null;

  public groups: UIChecklistGroup[] = [];

  // Is this an unsaved New Checklist?
  get isNew(): boolean {
    return !this.id;
  }

  // Define new UI view model structure
  public constructor(apiChecklist: APIChecklist|null = null) {
    if (apiChecklist) this.updateFromAPIResponse(apiChecklist);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiChecklist: APIChecklist) {
    this.apiSource = apiChecklist;
    this.id = apiChecklist._id?.$oid || null;

    this.name = apiChecklist.name || null;
    this.permitted_actions = apiChecklist.permitted_actions || [];
    this.sortRank = apiChecklist.sort_rank || null;
    this.updated_at = apiChecklist.updated_at || null;
    this.updated_by = apiChecklist.updated_by || null;
    this.checklist_task_completed_count = apiChecklist.checklist_task_completed_count || null;
    this.checklist_task_completed_fraction = apiChecklist.checklist_task_completed_fraction || null;
    this.checklist_task_count = apiChecklist.checklist_task_count || null;

    if (apiChecklist.groups) {
      this.groups = apiChecklist.groups.map((apiGroup: APIChecklistGroup) => {
        if (!apiGroup) { return new UIChecklistGroup(); }

        return new UIChecklistGroup(apiGroup);
      });
    }
  }

  // Build a copy of the checklist for the purpose of tracking unsaved changes
  // NOTE: this is intended to be the view model safe to use for input v-models
  public copyViewModel(): UIChecklist {
    const apiSource = this.apiSource as APIChecklist;
    if (!apiSource) return new UIChecklist();

    return new UIChecklist(apiSource);
  }

  // Build a copy of the checklist for the purpose of tracking unsaved changes
  // NOTE: this is intended to be the view model safe to use for input v-models
  public copyViewModelAndRetainNewItems(existingChecklist: UIChecklist): UIChecklist {
    const apiSource = this.apiSource as APIChecklist;
    if (!apiSource) return new UIChecklist();

    const newChecklist = new UIChecklist(apiSource);

    existingChecklist.groups.map((existingGroup: UIChecklistGroup) => {
      // fix 'new' task in existing groups
      if (existingGroup.showNew) {
        // if so find the location to attach
        newChecklist.groups.map((newGroup: UIChecklistGroup) => {
          // if find a match copy new task over
          if (newGroup.id === existingGroup.id) {
            newGroup.showNew = existingGroup.showNew; // preserve 'new task' show flag
            newGroup.newTask = existingGroup.newTask; // preserve 'new task' contents
          }
        });
      }
    });

    return newChecklist;    
  }

  // set group's showHiddenMessage by task
  public setShowHiddenMessage(task: UIChecklistTask): void {
    if (!task.group_id) return;
    // find group referenced in task and generate showHiddenMessage
    const group = this.groupAssociatedWithTask(task);
    if (!group) return;

    const name = task.name;
    const message = i18n.tc('validation.messages.checklist_hidden_task_message').toString();
    const showHiddenMessage = message.replace('__', name as string);
    group.showHiddenMessage = showHiddenMessage;
  }

  public groupAssociatedWithTask(task: UIChecklistTask): UIChecklistGroup|null {
    if (!task.group_id) return null;

    return (this.groups || []).find((group: UIChecklistGroup) => { return group.id === task.group_id; }) || null;
  }

  // find the task's that's closest to the one that's now hidden
  public setShowHiddenMessageNear(uiTask: UIChecklistTask): void {
    if (uiTask) {
      const group = this.findGroupById(uiTask.group_id);
      if (group) {
        const closestMatch = group.findClosestMatch(uiTask);
        group.showHiddenMessageNear = closestMatch ? closestMatch.id : null;
      }
    }
  }

  // find group by id
  public findGroupById(groupId: string|null): UIChecklistGroup|null {
    if (!groupId) return null;
    const found = this.groups.find((group: UIChecklistGroup) => {
      return group.id === groupId;
    });
    return found ? found : null;
  }

  // find task in checklist
  public findTaskInChecklist(uiTask: UIChecklistTask): UIChecklistTask|null {
    if (!uiTask) return null;
    const foundGroup = this.findGroupById(uiTask.group_id);
    if (!foundGroup) return null;

    const foundTask = foundGroup.findTaskById(uiTask.id);

    return foundTask ? foundTask : null;
  }

  public updateCounts(counts: APIChecklistCounts) {
    this.checklist_task_completed_count = counts.checklist_task_completed_count || null;
    this.checklist_task_completed_fraction = counts.checklist_task_completed_fraction || null;
    this.checklist_task_count = counts.checklist_task_count || null;
  }
}
