<template>
  <form>
    <sub-section
      sub-section-id="proto-sodium-meld-section"
      :title="title"
    >
    <template v-slot:contents>
      
      <!-- Filter Bar + Create Button -->
      <table-toolbar
        :createButton="true"
        createText="Create Sodium MELD"
        @table-create-row="clearForm"
      >
        <template v-slot:button-bar>
          <div class="filter-section-action-row">
            <div class="filter-section-wrapper">
              <filter-component
                fieldID="contactFilter"
                :showFilter="false"
                :showArchived="false"
              />
            </div>
          </div>
        </template>
      </table-toolbar>

      <!-- List of Items, or History List -->
      <table-list
        ref="sodiumMeldsTable"
        tabbableColumn="calculated_date"
        table-id="sodium-meld-table"
        :table-config="sodiumMeldTableConfig"
        @table-row-click="editSelected"
        :highlightSelection="true"
        :rowStyleClass="rowStyleClass"
      />

      <!-- Form layout -->
     
        <form-layout
        form-id="sodiemMeld-form">
          <template v-slot:title>
            <!-- Mode indicator / subsection form title -->
            <legend>
              <h5 v-if="isNew" class="legend-title">
                New Sodium Meld
              </h5>
              <h5 v-else class="legend-title has-guiding-text">
                Selected Sodium Meld
              </h5>
            </legend>
          </template>
          <template v-slot:contents>
            <div class="row">
              <div class="standard-form-group">
                <checkbox-input 
                  name="single-sample-date-time" 
                  labelName="Use Single Sample Date-time?"
                  inputId="single-sample-date-time" 
                  v-model="editState.use_single_sample_datetime" 
                  label='Yes' />
              </div>
              <div class="standard-form-group">
                <date-input
                  inputId="single-sample-date"
                  name='Sample Date'
                  :disabled="!editState.use_single_sample_datetime"
                  :rules="{required: editState.use_single_sample_datetime == true}"
                  v-model="editState.single_sample_date"   
                />
              </div>
              <div class="standard-form-group">
                <time-input
                  input-id="single-sample-time"
                  name='Sample Time'
                  v-model="editState.single_sample_time"
                  :disabled="!editState.use_single_sample_datetime"
                :rules="{required: editState.use_single_sample_datetime == true}"
                />
              </div>
              <div class="standard-form-group">
                <checkbox-input 
                  name="single-sample-anticoagulated" 
                  labelName="Sample Anticoagulated?"
                  inputId="single-sample-anticoagulated" 
                  v-model="editState.single_sample_anticoagulated" 
                  :disabled="!editState.use_single_sample_datetime"
                  label='Yes' />
              </div>
            </div>
            <div class="hr-break mt-2" />
            <div class="row">
              <div class="standard-form-group">
                <number-input
                  inputId="total_bilirubin"
                  name="Total Bilirubin"
                  :append="true"
                  rules="required"
                  appendText="µmol/L"
                  step="0.1"
                  v-model="editState.total_bilirubin"
                />
              </div>
              <div class="standard-form-group">
                <date-input
                  inputId="bilirubin-sample-date"
                  name='Sample Date'             
                  rules="required"
                  v-model="editState.bilirubin_sample_date"   
                />
              </div>
              <div class="standard-form-group">
                <time-input
                  input-id="bilirubin-sample-time"
                  name='Sample Time'
                  v-model="editState.bilirubin_sample_time"
                  rules="required"
                />
              </div>
              <div class="standard-form-group">
                <checkbox-input 
                  name="bilirubin-sample-anticoagulated" 
                  labelName="Sample Anticoagulated?"
                  inputId="bilirubin-sample-anticoagulated" 
                  v-model="editState.bilirubin_sample_anticoagulated" 
                  label='Yes' />
              </div> 
            </div>
            <div class="hr-break mt-2" />
            <div class="row">
              <div class="standard-form-group">
                <number-input
                  inputId="inr"
                  name="INR"
                  :append="true"
                  rules="required"
                  appendText="INR"
                  step="0.1"
                  v-model="editState.inr"
                />
              </div>
              <div class="standard-form-group">
                <date-input
                  inputId="inr-sample-date"
                  name='Sample Date'             
                  rules="required"
                  v-model="editState.inr_sample_date"   
                />
              </div>
              <div class="standard-form-group">
                <time-input
                  input-id="inr-sample-time"
                  name='Sample Time'
                  v-model="editState.inr_sample_time"
                  rules="required"
                />
              </div>
              <div class="standard-form-group">
                <checkbox-input 
                  name="inr-sample-anticoagulated" 
                  labelName="Sample Anticoagulated?"
                  inputId="inr-sample-anticoagulated" 
                  v-model="editState.inr_sample_anticoagulated" 
                  label='Yes' />
              </div>     
            </div>
            <div class="hr-break mt-2" />
            <div class="row">
              <div class="standard-form-group">
                <number-input
                  inputId="serum_albumin"
                  name="Serum Albumin"
                  :append="true"
                  rules="required"
                  appendText="g/L"
                  step="0.1"
                  v-model="editState.serum_albumin"
                />
              </div>
              <div class="standard-form-group">
                <date-input
                  inputId="serum_albumin-sample-date"
                  name='Sample Date'             
                  rules="required"
                  v-model="editState.serum_albumin_sample_date"   
                />
              </div>
              <div class="standard-form-group">
                <time-input
                  input-id="serum_albumin-sample-time"
                  name='Sample Time'
                  v-model="editState.serum_albumin_sample_time"
                  rules="required"
                />
              </div>
              <div class="standard-form-group">
                <checkbox-input 
                  name="serum_albumin-sample-anticoagulated" 
                  labelName="Sample Anticoagulated?"
                  inputId="serum_albumin-sample-anticoagulated" 
                  v-model="editState.serum_albumin_sample_anticoagulated" 
                  label='Yes' />
              </div>
            </div>
            <div class="hr-break mt-2" />
            <div class="row">
              <div class="standard-form-group">
                <number-input
                  inputId="serum_creatinine"
                  name="Serum Creatinine"
                  :append="true"
                  rules="required"
                  appendText="µmol"
                  step="0.1"
                  v-model="editState.serum_creatinine"
                />
              </div>
              <div class="standard-form-group">
                <date-input
                  inputId="serum_creatinine-sample-date"
                  name='Sample Date'             
                  rules="required"
                  v-model="editState.serum_creatinine_sample_date"   
                />
              </div>
              <div class="standard-form-group">
                <time-input
                  input-id="serum_creatinine-sample-time"
                  name='Sample Time'
                  v-model="editState.serum_creatinine_sample_time"
                  rules="required"
                />
              </div>
              <div class="standard-form-group">
                <checkbox-input 
                  name="serum_creatinine-sample-anticoagulated" 
                  labelName="Sample Anticoagulated?"
                  inputId="serum_creatinine-sample-anticoagulated" 
                  v-model="editState.serum_creatinine_sample_anticoagulated" 
                  label='Yes' />
              </div>
            </div>
            <div class="hr-break mt-2" />
            <div class="row">
              <div class="standard-form-group">
                <number-input
                  inputId="serum_sodium"
                  name="Serum Sodium"
                  :append="true"
                  rules="required"
                  appendText="mEq/L"
                  step="0.1"
                  v-model="editState.serum_sodium"
                />
              </div>
              <div class="standard-form-group">
                <date-input
                  inputId="serum_sodium-sample-date"
                  name='Sample Date'             
                  rules="required"
                  v-model="editState.serum_sodium_sample_date"   
                />
              </div>
              <div class="standard-form-group">
                <time-input
                  input-id="serum_creatinine-sample-time"
                  name='Sample Time'
                  v-model="editState.serum_creatinine_sample_time"
                  rules="required"
                />
              </div>
              <div class="standard-form-group">
                <checkbox-input 
                  name="serum_sodium-sample-anticoagulated" 
                  labelName="Sample Anticoagulated?"
                  inputId="serum_sodium-sample-anticoagulated" 
                  v-model="editState.serum_sodium_sample_anticoagulated" 
                  label='Yes' />
              </div>
            </div>

          </template>
        </form-layout>    
    </template>
    </sub-section>
    <sub-section
      sub-section-id="proto-sodium-meld-dialysis-section"
    >
    <template v-slot:contents>
      <!-- Form layout -->
      <form-layout
      form-id="dialysis-form">
        <template v-slot:contents>
          <div class="row">
            <div class="standard-form-group">
              <checkbox-input 
                name="on-dialysis" 
                labelName="On Dialysis?"
                inputId="on-dialysis" 
                v-model="editState.on_dialysis" 
                label='Yes' />
            </div>
            <div class="standard-form-group">
              <date-input
                inputId="last-dialysis-date"
                name='Last Dialysis Date'
                :disabled="!editState.on_dialysis"
                :rules="{required: editState.on_dialysis == true}"
                v-model="editState.last_dialysis_date"   
              />
            </div>
            <div class="standard-form-group">
              <select-input
                select-id="hepatic-encephalopathy"
                name='Hepatic Encephalopathy'
                :options="hepaticEncephalopathyOptions"
                v-model="editState.hepatic_encephalopathy">               
              </select-input>
            </div>
            <div class="standard-form-group">
              <date-input
                inputId="hepatic-encephalopathy-observation-date"
                name='Observation Date'
                v-model="editState.hepatic_encephalopathy_observation_date"   
              />
            </div>
            <div class="standard-form-group">
              <select-input
                select-id="ascites"
                name='Ascites'
                :options="ascitesOptions"
                v-model="editState.ascites">              
              </select-input>
            </div>
            <div class="standard-form-group">
              <date-input
                inputId="ascites-observation-date"
                name='Observation Date'
                v-model="editState.ascites_observation_date"  
              />
            </div>
            <div class="standard-form-group">
              <checkbox-input 
                name="tips-procedure" 
                labelName="TIPS Procedure?"
                inputId="tips-procedure" 
                v-model="editState.tips_procedure" 
                label='Yes'
               />
            </div>
            <div class="standard-form-group">
              <date-input
                inputId="tips-procedure-observation-date"
                name='Observation Date'
                v-model="editState.tips_procedures_observation_date"   
              />
            </div>         
          </div>   
        </template>
      </form-layout>
      
    </template>
  </sub-section>
  <sub-section
      sub-section-id="proto-sodium-meld-score-section"
    >
    <template v-slot:contents>
      <!-- Form layout -->
      <form-layout
      form-id="dialysis-score-form">
        <template v-slot:contents>
          <div class="row">
            <div class="standard-form-group">
                <text-input 
                  rules="required" 
                  inputId="sodium-meld-score" 
                  name="Sodium MELD Score"
                  v-model="editState.sodium_meld_score"/>
              </div>
          
            <div class="standard-form-group">
              <date-input
                inputId="calculated-date"
                rules="required" 
                name='Calculated Date'
                v-model="editState.calculated_date"   
              />
            </div>
            <div class="standard-form-group">
              <date-input
                inputId="expiration-date"
                rules="required" 
                name='Expiration Date'
                v-model="editState.expiration_date"   
              />
            </div>        
          </div>
          <div class="row">
         
         
          <div class="standard-form-group-large">
              <text-area-input input-id="meld-comments" name="Comments" rows="4" v-model="editState.comments"  />
            </div>
          </div>

        </template>
        <template v-slot:save>
          <save-toolbar
            :show="true"     
            ref="saveSodiumMeld"
            label="Save Sodium MELD"
            :cancelButton="true"
            @save="performSave()"
            @cancel="clearForm()"
          />
        </template>


      </form-layout>
      
    </template>
  </sub-section>
</form>
</template>


<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
  mixins
} from 'vue-facing-decorator';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
import TableList from '@/components/shared/TableList.vue';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import DateInput from "@/components/shared/DateInput.vue";
import TimeInput from '@/components/shared/TimeInput.vue';
import NumberInput from "@/components/shared/NumberInput.vue";
import SelectInput from '@/components/shared/SelectInput.vue';

import {
  SaveProvider,
  TableConfig,
} from '@/types';

const PAGE_SIZES = [5, 10, 25];
const EDITSTATE_KEY = 'editState';

interface SodiumMeldsForm {
  use_single_sample_datetime: boolean,
  id: string,
  single_sample_date: Date|null,
  single_sample_time: string,
  single_sample_anticoagulated: boolean,

  // Total Bilirubin 
  total_bilirubin: number | null
  bilirubin_sample_date: Date|null,
  bilirubin_sample_time: string,
  bilirubin_sample_anticoagulated: boolean

  // INR
  inr: number | null
  inr_sample_date: Date|null,
  inr_sample_time: string,
  inr_sample_anticoagulated: boolean,

  // Serum Albumin 
  serum_albumin: number | null,
  serum_albumin_sample_date: Date|null,
  serum_albumin_sample_time: string,
  serum_albumin_sample_anticoagulated: boolean

  // Serum Creatinine 
  serum_creatinine: number | null,
  serum_creatinine_sample_date: Date|null,
  serum_creatinine_sample_time: string,
  serum_creatinine_sample_anticoagulated: boolean,

  // Serum Sodium 
  serum_sodium: number | null,
  serum_sodium_sample_date: Date|null,
  serum_sodium_sample_time: string,
  serum_sodium_sample_anticoagulated: boolean,

  // Dialysis Section 
  on_dialysis: boolean,
  last_dialysis_date: string,
  hepatic_encephalopathy: string,
  hepatic_encephalopathy_observation_date: string,
  ascites: string,
  ascites_observation_date: string,
  tips_procedure: string,
  tips_procedures_observation_date: string,

  // Score Section 
  sodium_meld_score: string,
  calculated_date: Date|null,
  expiration_date: Date|null,
  comments: string
}

@Component({
  components: {
    CardSection,
    FilterComponent,
    SubSection,
    TableToolbar,
    TableList,
    TextInput,
    TextAreaInput,
    CheckboxInput,
    SaveToolbar,
    FormLayout,
    ActionToolbar,
    DateInput,
    TimeInput,
    NumberInput,
    SelectInput
  },
  emits: [
    'cancel',
  ],
})
export default class ProtoSodiumMelds extends mixins(DateUtilsMixin) {

  private title: any = "Sodium MELD";
  private isNew: any = true;
  private isArchiving = false;
  private isRestoring = false;
  private isLoadingActions = false;


// used by parent component
@Watch('editState.single_sample_date')
onDateValue(): void {
  if (this.editState.single_sample_date) {
    if (this.editState.use_single_sample_datetime == true) {
      const sameDate = this.editState.single_sample_date;
      this.editState.bilirubin_sample_date = sameDate;
      this.editState.serum_albumin_sample_date = sameDate;
      this.editState.serum_creatinine_sample_date = sameDate;
      this.editState.inr_sample_date = sameDate;
      this.editState.serum_sodium_sample_date = sameDate;
    }
  }
}

@Watch('editState.single_sample_time')
onTimeValue(): void {
  if (this.editState.single_sample_date) {
    if (this.editState.use_single_sample_datetime == true) {
      const sameTime = this.editState.single_sample_time;
      this.editState.bilirubin_sample_time = sameTime;
      this.editState.serum_albumin_sample_time = sameTime;
      this.editState.serum_creatinine_sample_time = sameTime;
      this.editState.inr_sample_time = sameTime;
      this.editState.serum_sodium_sample_time = sameTime;
    }
  }
}

@Watch('editState.single_sample_anticoagulated')
onAnticoagulatedValue(): void {
  if (this.editState.single_sample_anticoagulated) {
    if (this.editState.use_single_sample_datetime == true) {
      const sameValue = this.editState.single_sample_anticoagulated;
      this.editState.bilirubin_sample_anticoagulated = sameValue;
      this.editState.serum_albumin_sample_anticoagulated = sameValue;
      this.editState.serum_creatinine_sample_anticoagulated = sameValue;
      this.editState.inr_sample_anticoagulated = sameValue;
      this.editState.serum_sodium_sample_anticoagulated = sameValue;
    }
  }
}

  private editState: SodiumMeldsForm = {
    use_single_sample_datetime: false,
    id: '',
    single_sample_date: null,
    single_sample_time: '',
    single_sample_anticoagulated: false,
    
    // Total Bilirubin 
    total_bilirubin: null,
    bilirubin_sample_date: null,
    bilirubin_sample_time: "",
    bilirubin_sample_anticoagulated: false,

    // INR
    inr: null,
    inr_sample_date: null,
    inr_sample_time: "",
    inr_sample_anticoagulated: false,

    // Serum Albumin 
    serum_albumin: null,
    serum_albumin_sample_date: null,
    serum_albumin_sample_time: "",
    serum_albumin_sample_anticoagulated: false,  

    // Serum Creatinine 
    serum_creatinine: null,
    serum_creatinine_sample_date: null,
    serum_creatinine_sample_time: "",
    serum_creatinine_sample_anticoagulated: false,
      
    // Serum Sodium 
    serum_sodium: null,
    serum_sodium_sample_date: null,
    serum_sodium_sample_time: "",
    serum_sodium_sample_anticoagulated: false,

    // Dialysis Section 
    on_dialysis: false,
    last_dialysis_date: "",
    hepatic_encephalopathy: "",
    hepatic_encephalopathy_observation_date: "",
    ascites: "",
    ascites_observation_date: "",
    tips_procedure: "",
    tips_procedures_observation_date: "",

    // Score Section 
    sodium_meld_score: "",
    calculated_date: null,
    expiration_date: null,
    comments: ""
  };

  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  private sodiumMelds: any = [];

  private isTableUpdating = false;
  private taskInProgress = false;
  public perPage = 10;
  public currentPageTable = 1;
  private isLoading = true;
  private isSubSectionLoading = true;
  private selectedItem = '';
  private emptyContactMethodForm = '';
  private displayFilter = true;
  private setFilters: any = {};
  private modalMode = ""
  private rows = [];

  private hepaticEncephalopathyOptions = [{
    code: 0,
    value: 'None'
    }, {
      code: 1,
      value: '1 - 2'
    }, {
      code: 2,
      value: '3 - 4'
    }
  ];

  private ascitesOptions = [{
    code: 0,
    value: 'None'
    }, {
    code: 1,
    value: '1+'
    },
    {
    code: 2,
    value: '2+'
    },
    {
    code: 3,
    value: '3+'
    },
    {
    code: 4,
    value: '4+'
    }
  ];

  get sodiumMeldTableConfig(): TableConfig {
  return {
    data: this.tableRows || [],
    columns: [
      { label: 'Calculated Date', field: 'calculatedDateDisplayValue', width: '125px'},
      { label: 'Score', field: 'sodium_meld_score',  width: '50px' },
      { label: 'Total Bilirubin', field: 'total_bilirubin',  width: '125px' },
      { label: 'INR', field: 'inr',  width: '50px'},
      { label: 'Serum Sodium', field: 'serum_sodium',  width: '125px' },
      { label: 'Serum Creatinine', field: 'serum_creatinine',  width: '125px' },        
      { label: 'Serum Albumin', field: 'serum_albumin',  width: '125px' },
      { label: 'On Dialysis', field: 'on_dialysis',  width: ' 125px' },
      { label: 'Expiration Date', field: 'expirationDateDisplayValue', width: '125px'},
    ],
    empty: 'use the form below to add a new row',
    sortOptions: {
      enabled: false,
      initialSortBy: [{ field: 'lastUpdatedDateObject', type: 'desc' }]
    },
    pagination: true,
    paginationOptions: {
      enabled: true,
      perPageDropdown: PAGE_SIZES,
      dropdownAllowAll: false,
      position: 'bottom'
    }
  };
}

      // Each save toolbar will need a unique ref
get saveToolbar(): SaveToolbar {
  return this.$refs.saveSodiumMeld as SaveToolbar;
}

// NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
async mounted() {
  // No-op if prototypes not enabled
  if (!useCurrentPageStore().configuration.prototypes) return;

  // Make a per-recipient prototype bucket
  this.protoBucket = new UIPrototypeBucket(PROTOTYPES.LiverSodiumMeld, { recipientId: this.$route.params.id });

  // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
  const response: SodiumMeldsForm = await this.protoBucket.getItem(EDITSTATE_KEY);
  if (!response) return;

  this.sodiumMelds = response;
}

get tableRows() {
  const sodiumMelds = this.sodiumMelds || [];

  if (sodiumMelds.length > 0) {

  return sodiumMelds.map((meld: any) => {
    return {
      id: meld.id,
      use_single_sample_datetime: meld.use_single_sample_datetime,
      single_sample_date: meld.single_sample_date,
      single_sample_time: meld.single_sample_time,
      single_sample_anticoagulated: meld.single_sample_anticoagulated,

      // Total Bilirubin 
      total_bilirubin: meld.total_bilirubin || '-',
      bilirubin_sample_date: meld.bilirubin_sample_date,
      bilirubin_sample_time: meld.bilirubin_sample_time,
      bilirubin_sample_anticoagulated: meld.bilirubin_sample_anticoagulated,

      // INR 
      inr: meld.inr || '-',
      inr_sample_date: meld.inr_sample_date,
      inr_sample_time: meld.inr_sample_time,
      inr_sample_anticoagulated: meld.inr_sample_anticoagulated,

      // Serum Albumin  
      serum_albumin: meld.serum_albumin || '-',
      serum_albumin_sample_date: meld.serum_albumin_sample_date,
      serum_albumin_sample_time: meld.serum_albumin_sample_time,
      serum_albumin_sample_anticoagulated: meld.serum_albumin_sample_anticoagulated,

      // Serum Creatinine 
      serum_creatinine: meld.serum_creatinine || '-',
      serum_creatinine_sample_date: meld.serum_creatinine_sample_date,
      serum_creatinine_sample_time: meld.serum_creatinine_sample_time,
      serum_creatinine_sample_anticoagulated: meld.serum_creatinine_sample_anticoagulated,

      // Serum Sodium 
      serum_sodium: meld.serum_sodium || '-',
      serum_sodium_sample_date: meld.serum_sodium_sample_date,
      serum_sodium_sample_time: meld.serum_sodium_sample_time,
      serum_sodium_sample_anticoagulated: meld.serum_sodium_sample_anticoagulated,
      
      // Dialysis Section
      on_dialysis: meld.on_dialysis ? 'Yes' : 'No',
      last_dialysis_date: meld.last_dialysis_date,
      hepatic_encephalopathy: meld.hepatic_encephalopathy,
      hepatic_encephalopathy_observation_date: meld.hepatic_encephalopathy_observation_date,
      ascites: meld.ascites,
      ascites_observation_date: meld.ascites_observation_date,
      tips_procedure: meld.tips_procedure,
      tips_procedures_observation_date: meld.tips_procedures_observation_date,

      // Score Section
      sodium_meld_score: meld.sodium_meld_score || '-',
      calculated_date: meld.calculated_date,
      calculatedDateDisplayValue: this.parseDisplayDateUi(meld.calculated_date) || '-',
      expiration_date: meld.expiration_date,
      expirationDateDisplayValue: this.parseDisplayDateUi(meld.expiration_date) || '-',
      comments: meld.comments
    };
  });
}
}

public performSave(): any {
  // Call the 'startSaving' method to begin save animation
  if (this.saveToolbar) this.saveToolbar.startSaving();

  let sodiumMelds = this.sodiumMelds || [];

  const payload: SodiumMeldsForm = {
    id: Math.random().toString(16).slice(2),
    use_single_sample_datetime: this.editState.use_single_sample_datetime,
    single_sample_date: this.editState.single_sample_date,
    single_sample_time: this.editState.single_sample_time,
    single_sample_anticoagulated: this.editState.single_sample_anticoagulated,

    // Total Bilirubin
    total_bilirubin: this.editState.total_bilirubin,
    bilirubin_sample_date: this.editState.bilirubin_sample_date,
    bilirubin_sample_time: this.editState.bilirubin_sample_time,
    bilirubin_sample_anticoagulated: this.editState.bilirubin_sample_anticoagulated,

    // INR 
    inr: this.editState.inr,
    inr_sample_date: this.editState.inr_sample_date,
    inr_sample_time: this.editState.inr_sample_time,
    inr_sample_anticoagulated: this.editState.inr_sample_anticoagulated,

    // Serum Albumin
    serum_albumin: this.editState.serum_albumin,
    serum_albumin_sample_date: this.editState.serum_albumin_sample_date,
    serum_albumin_sample_time: this.editState.serum_albumin_sample_time,
    serum_albumin_sample_anticoagulated: this.editState.serum_albumin_sample_anticoagulated,

    // Serum Creatinine
    serum_creatinine: this.editState.serum_creatinine,
    serum_creatinine_sample_date: this.editState.serum_creatinine_sample_date,
    serum_creatinine_sample_time: this.editState.serum_creatinine_sample_time,
    serum_creatinine_sample_anticoagulated: this.editState.serum_creatinine_sample_anticoagulated,

    // Serum Sodium
    serum_sodium: this.editState.inr,
    serum_sodium_sample_date: this.editState.serum_sodium_sample_date,
    serum_sodium_sample_time: this.editState.serum_sodium_sample_time,
    serum_sodium_sample_anticoagulated: this.editState.serum_sodium_sample_anticoagulated,

    // Dialysis Section
    on_dialysis: this.editState.on_dialysis,
    last_dialysis_date: this.editState.last_dialysis_date,
    hepatic_encephalopathy: this.editState.hepatic_encephalopathy,
    hepatic_encephalopathy_observation_date: this.editState.hepatic_encephalopathy_observation_date,
    ascites: this.editState.ascites,
    ascites_observation_date: this.editState.ascites_observation_date,
    tips_procedure: this.editState.tips_procedure,
    tips_procedures_observation_date: this.editState.tips_procedures_observation_date,

    // Score Section
    sodium_meld_score: this.editState.sodium_meld_score,
    calculated_date: this.editState.calculated_date,
    expiration_date: this.editState.expiration_date,
    comments: this.editState.comments
  };

  if (!this.isNew ){  
    payload.id = this.editState.id;
    const foundIndex = sodiumMelds.findIndex((meld: any) => meld.id == this.editState.id);
    
    if (foundIndex != -1) {
      sodiumMelds[foundIndex] = payload;
    }
  } else {  
    sodiumMelds.push(payload);
  }
  
  this.protoBucket.setItem(EDITSTATE_KEY, sodiumMelds);

  // Call the 'showFakeSuccess' method to complete successful save animation
  if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
  this.clearForm();
} 


private editSelected(row: any) {
    if (row) {  
      this.editState.id = row.row.id;
      this.editState.use_single_sample_datetime = row.row.use_single_sample_datetime;
      this.editState.single_sample_date = row.row.single_sample_date;
      this.editState.single_sample_time = row.row.single_sample_time;
      this.editState.single_sample_anticoagulated = row.row.single_sample_anticoagulated;

      // Total Bilirubin
      this.editState.total_bilirubin = row.row.total_bilirubin;
      this.editState.bilirubin_sample_date = row.row.bilirubin_sample_date;
      this.editState.bilirubin_sample_time = row.row.bilirubin_sample_time;
      this.editState.bilirubin_sample_anticoagulated = row.row.bilirubin_sample_anticoagulated;

      // INR 
      this.editState.inr = row.row.inr;
      this.editState.inr_sample_date = row.row.inr_sample_date;
      this.editState.inr_sample_time = row.row.inr_sample_time;
      this.editState.inr_sample_anticoagulated = row.row.inr_sample_anticoagulated;

      // Serum Albumin
      this.editState.serum_albumin = row.row.serum_albumin;
      this.editState.serum_albumin_sample_date = row.row.serum_albumin_sample_date;
      this.editState.serum_albumin_sample_time = row.row.serum_albumin_sample_time;
      this.editState.serum_albumin_sample_anticoagulated = row.row.serum_albumin_sample_anticoagulated;

      // Serum Creatinine
      this.editState.serum_creatinine = row.row.serum_creatinine;
      this.editState.serum_creatinine_sample_date = row.row.serum_creatinine_sample_date;
      this.editState.serum_creatinine_sample_time = row.row.serum_creatinine_sample_time;
      this.editState.serum_creatinine_sample_anticoagulated = row.row.serum_creatinine_sample_anticoagulated;

      // Serum Sodium
      this.editState.serum_sodium = row.row.serum_sodium;
      this.editState.serum_sodium_sample_date = row.row.serum_sodium_sample_date;
      this.editState.serum_sodium_sample_time = row.row.serum_sodium_sample_time;
      this.editState.serum_sodium_sample_anticoagulated = row.row.serum_sodium_sample_anticoagulated;

      // Dialysis Section
      this.editState.on_dialysis =  row.row.on_dialysis;
      this.editState.last_dialysis_date = row.row.last_dialysis_date;
      this.editState.hepatic_encephalopathy = row.row.hepatic_encephalopathy;
      this.editState.hepatic_encephalopathy_observation_date = row.row.hepatic_encephalopathy_observation_date;
      this.editState.ascites = row.row.ascites;
      this.editState.ascites_observation_date = row.row.ascites_observation_date;
      this.editState.tips_procedure = row.row.tips_procedure;
      this.editState.tips_procedures_observation_date = row.row.tips_procedures_observation_date;

      // Score Section
      this.editState.sodium_meld_score = row.row.sodium_meld_score;
      this.editState.calculated_date = row.row.calculated_date;
      this.editState.expiration_date = row.row.expiration_date;
      this.editState.comments = row.row.comments;

      this.isNew = false;
    }
  }

  public clearForm(): any {
    this.isNew = false;
    this.editState.use_single_sample_datetime = false;
    this.editState.single_sample_date = null;
    this.editState.single_sample_time = '';
    this.editState.single_sample_anticoagulated = false;

    // Total Bilirubin
    this.editState.total_bilirubin = null;
    this.editState.bilirubin_sample_date = null;
    this.editState.bilirubin_sample_time = '';
    this.editState.bilirubin_sample_anticoagulated = false;

    // INR 
    this.editState.inr = null;
    this.editState.inr_sample_date = null;
    this.editState.inr_sample_time = '';
    this.editState.inr_sample_anticoagulated = false;

    // Serum Albumin
    this.editState.serum_albumin = null;
    this.editState.serum_albumin_sample_date = null;
    this.editState.serum_albumin_sample_time = "";
    this.editState.serum_albumin_sample_anticoagulated = false;

    // Serum Creatinine
    this.editState.serum_creatinine = null;
    this.editState.serum_creatinine_sample_date = null;
    this.editState.serum_creatinine_sample_time = "";
    this.editState.serum_creatinine_sample_anticoagulated = false;

    // Serum Sodium
    this.editState.serum_sodium = null;
    this.editState.serum_sodium_sample_date = null;
    this.editState.serum_sodium_sample_time = "";
    this.editState.serum_sodium_sample_anticoagulated = false;
    
    // Dialysis Section
    this.editState.on_dialysis = false;
    this.editState.last_dialysis_date = "";
    this.editState.hepatic_encephalopathy = "";
    this.editState.hepatic_encephalopathy_observation_date = "";
    this.editState.ascites = "";
    this.editState.ascites_observation_date = "";
    this.editState.tips_procedure = "";
    this.editState.tips_procedures_observation_date = "";

    // Score Section
    this.editState.sodium_meld_score = "";
    this.editState.calculated_date = null;
    this.editState.expiration_date = null;
    this.editState.comments = "";
  }

  rowStyleClass(row: any) {
  // if row editable, change color
  return row.editable ? 'tr-highlight-green' : null;
}

  // Reference to table
  get sodiumMeldsTable(): any {
    return this.$refs.sodiumMeldsTable as any ;
  }

   // Saved successfully
   public onSaveSuccess() {
    this.sodiumMeldsTable.resetSelection();
  }

  // Deleted item
  public onDeleted() {
    this.sodiumMeldsTable.resetSelection();
  }


  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveSodiumMeld as unknown as SaveProvider;

    saveToolbar.resetSaveToolbar();
  }
  
}



</script>

<style>
ul.table-list-style {
  -webkit-padding-start: 1rem;
  padding-inline-start: 1rem;
}

ul.no-table-list-style {
list-style: none;
padding-inline-start: 0;
}

</style>
