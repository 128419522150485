import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-wrap" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "nav-wrapper" }
const _hoisted_4 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_page_top = _resolveComponent("page-top")!
  const _component_recipient_sticky_summary = _resolveComponent("recipient-sticky-summary")!
  const _component_side_nav_journey = _resolveComponent("side-nav-journey")!
  const _component_recipient_summary = _resolveComponent("recipient-summary")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_page_top, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_link, { to: { name: 'list-recipients' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('recipients')), 1)
          ]),
          _: 1
        }),
        _createTextVNode(" / "),
        _createVNode(_component_router_link, {
          to: { name: 'edit-recipient', params: { id: _ctx.recipientId} }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.recipientDisplayName), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createTextVNode(" / " + _toDisplayString(_ctx.organDescription), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_recipient_sticky_summary),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_side_nav_journey),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_recipient_summary),
            _createVNode(_component_validation_observer, {
              ref: "validations",
              onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.organComponent), {
                  ref: "organComponent",
                  "new-journey": false,
                  disabled: !_ctx.canSaveGetter(false),
                  onLoaded: _cache[0] || (_cache[0] = (ref) => _ctx.loaded(ref)),
                  onSectionsToLoad: _cache[1] || (_cache[1] = (ref) => _ctx.sectionsToLoad(ref))
                }, null, 40, ["disabled"]))
              ]),
              _: 1
            }, 512)
          ])
        ])
      ])
    ])
  ]))
}