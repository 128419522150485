// Master list of prototypes
export enum PROTOTYPES {
  ChecklistReorder = 'proto_checklists_reorder',
  CommunicationInformation = 'proto_communication_information',
  TransportationAndMobility = 'proto_transportation_and_mobility',
  PaceAndEngagement = 'proto_pace_and_engagement',
  PrioritizationReport = 'proto_prioritization_report',
  SchedulingPreferences = 'proto_scheduling_preferences',
  ProtoRecipientsListings = 'proto_recipient_listings',
  CareGivers = 'proto_care_givers',
  WidgetReminders = 'proto_widget_reminders',
  LiverSpecificDetails = 'proto_liver_specific_details',
  LiverSodiumMeld = 'proto_liver_sodium_meld',
  EgfrAndDiaylsis = 'proto_egfr_and_dialysis',
  EgfrSection = 'proto_egfr_section',
  DiaylsisEvents = 'proto_dialysis_events',
  KidneySpecificDetails = 'proto_kidney_specific_details'
}
