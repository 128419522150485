<template>
  <div>
    <loading-recipient-page v-if="!dispatchEventsComplete" />
    <template v-else>
      <page-top>
        <router-link :to="{ name: 'list-recipients' }">
          {{ $t('recipients') }}
        </router-link>
        / {{ $t('new') }}
      </page-top>

      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <side-nav-recipient-profile :newRecipient="true" />
            <div class="page-content">
              <!-- Card Sections -->
              <demographics
                ref="demographics"
                :newRecipient="true"
                :canSave="isGroupWriteable('recipient_personal')"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import { Component } from 'vue-facing-decorator';
import { Vue } from "vue-facing-decorator";
import { Recipient } from '@/store/recipients/types';
import PageTop from '@/components/shared/PageTop.vue';
import Demographics from '@/components/recipients/Demographics.vue';
import SideNavRecipientProfile from "@/components/recipients/side-nav/SideNavRecipientProfile.vue";
import { i18nMessages } from '@/i18n';
import LoadingRecipientPage from '@/components/shared/loading-skeletons/LoadingRecipientPage.vue';

@Component({
  components: {
    LoadingRecipientPage,
    PageTop,
    Demographics,
    SideNavRecipientProfile,
  },
  ...i18nMessages([
    require('../_locales/common.json'),
  ]),
})
export default class NewRecipient extends Vue {
  // State
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  // Getters
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  private dispatchEventsComplete = false;

  public mounted(): void {
    Promise.all([
      this.$store.dispatch('recipients/new'),
      this.$store.dispatch('validations/loadNew', { view: 'recipients', action: 'new' }),
    ]).finally(() => {
      this.dispatchEventsComplete = true;
      this.$store.dispatch('utilities/scrollBehavior');
    });
  }
}
</script>
