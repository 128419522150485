export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "recipient_status_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient Status"])},
        "graft_status_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graft Status"])},
        "follow_up_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Follow-Up"])},
        "loss_of_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loss of Contact"])},
        "error": {
          "unexpected_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot save: unexpected error"])},
          "forbidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot save: forbidden"])},
          "field_level_validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot save: see error messages above"])},
          "internal_server_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot save: internal server error"])}
        },
        "min_age_yrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Donor Age"])},
        "max_age_yrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Donor Age"])},
        "min_height_cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Donor Height"])},
        "max_height_cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Donor Height"])},
        "min_weight_kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Donor Weight"])},
        "max_weight_kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Donor Weight"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
        "restored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restored"])},
        "original_entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original Entry"])},
        "application_short_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo"])},
        "application_long_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo"])},
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
        "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
        "en_tgln": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN English"])},
        "fr_tgln": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN French"])},
        "en_tq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ English"])},
        "fr_tq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ French"])},
        "Request failed with status code 401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your session has timed out, please log in again."])},
        "control_that_returns_to_previous_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A control that returns to the previous page"])},
        "measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurements"])},
        "exceptional_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceptional Distribution"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "allocation_stage": {
          "recommending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Running"])},
          "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Run"])},
          "offering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offering"])},
          "offer-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Accepted"])},
          "offer-confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Accepted"])},
          "discontinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinued"])},
          "superceded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superceded"])},
          "concluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluded"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
        },
        "organ_donation_status": {
          "transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplanted"])}
        },
        "offer_status": {
          "recommending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Running"])},
          "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation Run"])},
          "offering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offering"])},
          "offer-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Accepted"])},
          "offer-confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Accepted"])},
          "discontinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinued"])},
          "superceded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superceded"])},
          "concluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluded"])},
          "transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplanted"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
          "primary-offer-pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Offer Pending"])},
          "primary-offer-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Offer Sent"])}
        },
        "permission-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to view the requested page"])},
        "urgent_recipient_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgent Patient"])},
        "urgent_recipient_hover_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgent Patient flag will be removed automatically when missing Personal and Contact information is provided."])},
        "urgent_journey_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgent Listing"])},
        "ctr_sync_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration Sync Events"])},
        "ipos": {
          "hsp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSP"])},
          "hsh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSH"])}
        },
        "page": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
          "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
          "recipient": {
            "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients"])},
            "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients / New"])},
            "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Patients / ", _interpolate(_named("recipient"))])},
            "new_organ": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Patients / ", _interpolate(_named("recipient")), " / ", _interpolate(_named("organ"))])},
            "edit_organ": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Patients / ", _interpolate(_named("recipient")), " / ", _interpolate(_named("organ"))])},
            "policy_exemptions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Patients / ", _interpolate(_named("recipient")), " / Policy Exemptions"])},
            "hla": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Patients / ", _interpolate(_named("recipient")), " / HLA Information"])}
          },
          "donor": {
            "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donors"])},
            "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donors"])},
            "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donors / New"])},
            "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Donors / ", _interpolate(_named("donor"))])},
            "organ_allocation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Donors / ", _interpolate(_named("donor")), " / ", _interpolate(_named("organ"))])}
          },
          "living_donor": {
            "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donors"])},
            "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donors / New"])},
            "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Living Donors / ", _interpolate(_named("livingDonor"))])},
            "organ_allocation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Living Donors / ", _interpolate(_named("livingDonor")), " / ", _interpolate(_named("organ"))])}
          },
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
          "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
          "waitlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waitlist"])},
          "prioritization_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritization Report"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
          "manage_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration / Users"])},
          "user_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Page"])},
          "ctr_integration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR Integration"])}
        },
        "fields": {
          "plan_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Type"])},
          "recipient_birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
          "recipient_status_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient Status"])},
          "serum_creatinine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serum Creatinine"])},
          "returned_to_chronic_dialysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returned to Chronic Dialysis"])}
        },
        "custom_errors": {
          "Email Address": {
            "validation": {
              "messages": {
                "format_mask:email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required format is: address", "@", "example.com"])}
              }
            }
          },
          "transplantDetails": {
            "check_not_ctr_synced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant data cannot be deleted for an HSH/HSP patient. If you need to clear the transplant data for this patient, please submit an urgent priority service request to Service Desk to delete the data in OATS and contact CTR customer support to delete the data in CTR."])}
          },
          "Test Date": {
            "validation": {
              "messages": {
                "not_blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both Date and Time are required."])}
              }
            }
          },
          "Test Time (EST)": {
            "validation": {
              "messages": {
                "not_blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both Date and Time are required."])}
              }
            }
          },
          "Sample Date": {
            "validation": {
              "messages": {
                "date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both Date and Time are required."])}
              }
            }
          },
          "Sample Time (EST)": {
            "validation": {
              "messages": {
                "date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both Date and Time are required."])}
              }
            }
          }
        },
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])}
      },
      "fr": {
        "recipient_status_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient Status"])},
        "graft_status_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graft Status"])},
        "follow_up_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Follow-Up"])},
        "loss_of_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loss of Contact"])},
        "error": {
          "unexpected_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de sauver : erreur inattendue"])},
          "forbidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de sauver : interdite"])},
          "field_level_validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de sauver : voyez les messages d'erreur ci-dessus"])},
          "internal_server_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de sauver : erreur interne du serveur"])}
        },
        "min_age_yrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Donor Age"])},
        "max_age_yrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Donor Age"])},
        "min_height_cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Donor Height"])},
        "max_height_cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Donor Height"])},
        "min_weight_kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Donor Weight"])},
        "max_weight_kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Donor Weight"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
        "restored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restored"])},
        "original_entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original Entry"])},
        "application_short_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo"])},
        "application_long_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo"])},
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
        "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
        "en_tgln": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais TGLN"])},
        "fr_tgln": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français TGLN"])},
        "en_tq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais TQ"])},
        "fr_tq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français TQ"])},
        "Request failed with status code 401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your session has timed out, please log in again."])},
        "control_that_returns_to_previous_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un contrôle qui retourne à la page précédente"])},
        "measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensurations"])},
        "exceptional_distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution Exceptionnelle"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "allocation_stage": {
          "recommending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation en cours"])},
          "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycle d'allocation"])},
          "offering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre"])},
          "offer-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre acceptée"])},
          "offer-confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer acceptée"])},
          "discontinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandonné"])},
          "superceded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacé"])},
          "concluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclu"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])}
        },
        "organ_donation_status": {
          "transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplanté"])}
        },
        "offer_status": {
          "recommending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation en cours"])},
          "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycle d'allocation"])},
          "offering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre"])},
          "offer-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre acceptée"])},
          "offer-confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer acceptée"])},
          "discontinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandonné"])},
          "superceded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacé"])},
          "concluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclu"])},
          "transplanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplanté"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
          "primary-offer-pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre primaire en attente"])},
          "primary-offer-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre primaire envoyée"])}
        },
        "permission-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas la permission d'afficher la page demandée."])},
        "urgent_recipient_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient urgent"])},
        "urgent_recipient_hover_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'indicateur de destinataire urgent sera automatiquement supprimé lorsque des informations personnelles et de contact manquantes sont fournies."])},
        "urgent_journey_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annonce urgente"])},
        "ctr_sync_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration Sync Events"])},
        "ipos": {
          "hsp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSP"])},
          "hsh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HSH"])}
        },
        "page": {
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours..."])},
          "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
          "recipient": {
            "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients"])},
            "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients / Nouveau profil"])},
            "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Patients / ", _interpolate(_named("recipient"))])},
            "new_organ": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Patients / ", _interpolate(_named("recipient")), " / ", _interpolate(_named("organ"))])},
            "edit_organ": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Patients / ", _interpolate(_named("recipient")), " / ", _interpolate(_named("organ"))])},
            "policy_exemptions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Patients / ", _interpolate(_named("recipient")), " / Derogation"])},
            "hla": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Patients / ", _interpolate(_named("recipient")), " / HLA Information"])}
          },
          "donor": {
            "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneurs"])},
            "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneurs"])},
            "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneurs / Nouveau profil"])},
            "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Donneurs / ", _interpolate(_named("donor"))])},
            "organ_allocation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Donneurs / ", _interpolate(_named("donor")), " / ", _interpolate(_named("organ"))])}
          },
          "living_donor": {
            "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneurs vivants"])},
            "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donneurs vivants / Nouveau profil"])},
            "edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Donneurs vivants / ", _interpolate(_named("livingDonor"))])},
            "organ_allocation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Donneurs vivants / ", _interpolate(_named("livingDonor")), " / ", _interpolate(_named("organ"))])}
          },
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
          "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports opérationnels"])},
          "waitlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste d'attente"])},
          "prioritization_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritization Report"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
          "manage_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration / Profils d'utilisateurs"])},
          "user_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil utilisateur"])},
          "ctr_integration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration du RCT"])}
        },
        "fields": {
          "plan_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de régime d'assurance"])},
          "recipient_birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
          "recipient_status_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut du patient"])},
          "serum_creatinine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créatinine sérique"])},
          "returned_to_chronic_dialysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la dialyse chronique"])}
        },
        "custom_errors": {
          "Adresse électronique": {
            "validation": {
              "messages": {
                "format_mask:email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le format requis est : adresse", "@", "exemple.com"])}
              }
            }
          },
          "transplantDetails": {
            "check_not_ctr_synced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant data cannot be deleted for an HSH/HSP patient. If you need to clear the transplant data for this patient, please submit an urgent priority service request to Service Desk to delete the data in OATS and contact CTR customer support to delete the data in CTR."])}
          },
          "Test Date": {
            "validation": {
              "messages": {
                "not_blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both Date and Time are required."])}
              }
            }
          },
          "Test Date (EST)": {
            "validation": {
              "messages": {
                "not_blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both Date and Time are required."])}
              }
            }
          },
          "Sample Date": {
            "validation": {
              "messages": {
                "date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both Date and Time are required."])}
              }
            }
          },
          "Sample Time (EST)": {
            "validation": {
              "messages": {
                "date_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both Date and Time are required."])}
              }
            }
          }
        },
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus ancien"])},
        "newer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récent"])},
        "results_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats par page"])}
      },
      "en_tgln": {
        "application_short_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OATS"])},
        "application_long_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Allocation and Transplant System"])},
        "urgent_recipient_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgent Recipient"])},
        "urgent_recipient_hover_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgent Recipient flag will be removed automatically when missing Personal and Contact information is provided."])},
        "fields": {
          "recipient_status_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Status"])}
        },
        "custom_errors": {
          "transplantDetails": {
            "check_not_ctr_synced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant data cannot be deleted for an HSH/HSP recipient. If you need to clear the transplant data for this recipient, please submit an urgent priority service request to Service Desk to delete the data in OATS and contact CTR customer support to delete the data in CTR."])}
          }
        }
      },
      "fr_tgln": {
        "application_short_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OATS"])},
        "application_long_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système d'attribution et de transplantation d'organes"])},
        "custom_errors": {
          "transplantDetails": {
            "check_not_ctr_synced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant data cannot be deleted for an HSH/HSP recipient. If you need to clear the transplant data for this recipient, please submit an urgent priority service request to Service Desk to delete the data in OATS and contact CTR customer support to delete the data in CTR."])}
          }
        }
      }
    }
  })
}
