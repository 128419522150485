import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "content-wrap" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = { class: "nav-wrapper" }
const _hoisted_5 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_recipient_page = _resolveComponent("loading-recipient-page")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_page_top = _resolveComponent("page-top")!
  const _component_recipient_sticky_summary = _resolveComponent("recipient-sticky-summary")!
  const _component_side_nav_journey = _resolveComponent("side-nav-journey")!
  const _component_recipient_summary = _resolveComponent("recipient-summary")!
  const _component_policy_exemptions = _resolveComponent("policy-exemptions")!
  const _component_policy_attachments = _resolveComponent("policy-attachments")!
  const _component_policy_responses = _resolveComponent("policy-responses")!
  const _component_policy_final_decisions = _resolveComponent("policy-final-decisions")!
  const _component_validation_observer = _resolveComponent("validation-observer")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.journey)
      ? (_openBlock(), _createBlock(_component_loading_recipient_page, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, { to: { name: 'list-recipients' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('recipients')), 1)
                ]),
                _: 1
              }),
              _createTextVNode(" / "),
              _createVNode(_component_router_link, {
                to: { name: 'edit-recipient', params: { id: _ctx.recipientId} }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.recipientDisplayName), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createTextVNode(" / " + _toDisplayString(_ctx.organDescription), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_recipient_sticky_summary),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_side_nav_journey),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_recipient_summary),
                  (_ctx.groupExists('journey_policy_exemption'))
                    ? (_openBlock(), _createBlock(_component_card_section, {
                        key: 0,
                        "section-id": "policy-exemptions",
                        ref: "saveExemptions",
                        "lookups-to-load": _ctx.lookupsToLoad
                      }, {
                        header: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('policy_exemptions')), 1)
                        ]),
                        body: _withCtx(() => [
                          _createVNode(_component_validation_observer, {
                            ref: "validations",
                            autocomplete: "off",
                            tag: "form"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_policy_exemptions, {
                                ref: "policyExemptions",
                                canSave: _ctx.canSave,
                                onClear: _ctx.resetValidationErrors,
                                "new-journey": _ctx.newJourney,
                                onLoaded: _cache[0] || (_cache[0] = (ref) => _ctx.loaded(ref)),
                                onHandleErrors: _ctx.handleErrors,
                                onSelected: _ctx.selectedExemption
                              }, null, 8, ["canSave", "onClear", "new-journey", "onHandleErrors", "onSelected"]),
                              _createVNode(_component_policy_attachments, {
                                ref: "policyAttachments",
                                canSave: _ctx.canSave,
                                onClear: _ctx.resetValidationErrors,
                                "new-journey": _ctx.newJourney,
                                onLoaded: _cache[1] || (_cache[1] = (ref) => _ctx.loaded(ref)),
                                onHandleErrors: _ctx.handleErrors,
                                onReloadIndex: _ctx.reloadExemptions
                              }, null, 8, ["canSave", "onClear", "new-journey", "onHandleErrors", "onReloadIndex"]),
                              _createVNode(_component_policy_responses, {
                                ref: "policyResponses",
                                canSave: _ctx.canSave,
                                onClear: _ctx.resetValidationErrors,
                                "new-journey": _ctx.newJourney,
                                onLoaded: _cache[2] || (_cache[2] = (ref) => _ctx.loaded(ref)),
                                onHandleErrors: _ctx.handleErrors,
                                onReloadIndex: _ctx.reloadExemptions
                              }, null, 8, ["canSave", "onClear", "new-journey", "onHandleErrors", "onReloadIndex"]),
                              _createVNode(_component_policy_final_decisions, {
                                ref: "policyFinalDecisions",
                                canSave: _ctx.canSave,
                                onClear: _ctx.resetValidationErrors,
                                "new-journey": _ctx.newJourney,
                                onLoaded: _cache[3] || (_cache[3] = (ref) => _ctx.loaded(ref)),
                                onHandleErrors: _ctx.handleErrors,
                                onReloadIndex: _ctx.reloadExemptions
                              }, null, 8, ["canSave", "onClear", "new-journey", "onHandleErrors", "onReloadIndex"])
                            ]),
                            _: 1
                          }, 512)
                        ]),
                        _: 1
                      }, 8, ["lookups-to-load"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ]))
  ]))
}