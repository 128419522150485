import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "filter-section-action-row" }
const _hoisted_2 = { class: "filter-section-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_4 = {
  key: 1,
  class: "legend-title has-guiding-text"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "standard-form-group" }
const _hoisted_12 = { class: "standard-form-group" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "standard-form-group" }
const _hoisted_15 = { class: "standard-form-group" }
const _hoisted_16 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createElementBlock("form", null, [
    _createVNode(_component_sub_section, {
      "sub-section-id": "proto-diaylsis-events-section",
      title: _ctx.title
    }, {
      contents: _withCtx(() => [
        _createVNode(_component_table_toolbar, {
          createButton: true,
          createText: "Create Diaylsis Event",
          onTableCreateRow: _ctx.clearForm
        }, {
          "button-bar": _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_filter_component, {
                  fieldID: "contactFilter",
                  showFilter: false,
                  showArchived: false
                })
              ])
            ])
          ]),
          _: 1
        }, 8, ["onTableCreateRow"]),
        _createVNode(_component_table_list, {
          ref: "dialysiseventsTable",
          tabbableColumn: "calculated_date",
          "table-id": "diaylsis-event-table",
          "table-config": _ctx.DiaylsisEventsTableConfig,
          onTableRowClick: _ctx.editSelected,
          highlightSelection: true,
          rowStyleClass: _ctx.rowStyleClass
        }, null, 8, ["table-config", "onTableRowClick", "rowStyleClass"]),
        _createVNode(_component_form_layout, { "form-id": "sodiemMeld-form" }, {
          title: _withCtx(() => [
            _createElementVNode("legend", null, [
              (_ctx.isNew)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_3, " New Dialysis Event "))
                : (_openBlock(), _createElementBlock("h5", _hoisted_4, " Selected Dialysis Event "))
            ])
          ]),
          contents: _withCtx(() => [
            _createVNode(_component_form_layout, { "form-id": "dialysis-score-form" }, {
              contents: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_select_input, {
                      "select-id": "reason-for-dialysis?",
                      name: "Reason for Dialysis?",
                      rules: "required",
                      options: _ctx.dialysisReasonOptions,
                      modelValue: _ctx.editState.dialysis_reason,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.dialysis_reason) = $event))
                    }, null, 8, ["options", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_date_input, {
                      inputId: "start-date",
                      rules: "required",
                      name: "Start Date",
                      modelValue: _ctx.editState.start_date,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.start_date) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_date_input, {
                      inputId: "end-date",
                      rules: "required",
                      name: "End Date",
                      modelValue: _ctx.editState.end_date,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.end_date) = $event))
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_select_input, {
                      "select-id": "dialysis-type",
                      name: "Dialysis Type",
                      options: _ctx.dialysisTypeOptions,
                      modelValue: _ctx.editState.dialysis_type,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.dialysis_type) = $event))
                    }, null, 8, ["options", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_select_input, {
                      "select-id": "access-mode",
                      name: "Access Mode",
                      options: _ctx.dialysisTypeOptions,
                      modelValue: _ctx.editState.access_mode,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editState.access_mode) = $event))
                    }, null, 8, ["options", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_select_input, {
                      "select-id": "access-laterality",
                      name: "Access Laterality",
                      options: _ctx.accessLateralityOptions,
                      modelValue: _ctx.editState.access_laterality,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.access_laterality) = $event))
                    }, null, 8, ["options", "modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_select_input, {
                      "select-id": "location",
                      name: "Location",
                      options: _ctx.locationOptions,
                      modelValue: _ctx.editState.location,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editState.location) = $event))
                    }, null, 8, ["options", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_text_input, {
                      inputId: "facility-name",
                      name: "Facility Name",
                      disabled: !_ctx.editState.location || _ctx.editState.location == '0',
                      modelValue: _ctx.editState.facility_name,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.facility_name) = $event))
                    }, null, 8, ["disabled", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_text_input, {
                      inputId: "facility-phone-number",
                      name: "Facility Phone Number",
                      mask: _ctx.getMask,
                      disabled: !_ctx.editState.location || _ctx.editState.location == '0',
                      modelValue: _ctx.editState.facility_phone_number,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editState.facility_phone_number) = $event))
                    }, null, 8, ["mask", "disabled", "modelValue"])
                  ])
                ])
              ]),
              save: _withCtx(() => [
                _createVNode(_component_save_toolbar, {
                  show: true,
                  ref: "saveDialysisEvent",
                  label: "Save Dialysis Event",
                  cancelButton: true,
                  onSave: _cache[9] || (_cache[9] = ($event: any) => (_ctx.performSave())),
                  onCancel: _cache[10] || (_cache[10] = ($event: any) => (_ctx.clearForm()))
                }, null, 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}