import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "content-wrap" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = { class: "nav-wrapper" }
const _hoisted_5 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_recipient_page = _resolveComponent("loading-recipient-page")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_page_top = _resolveComponent("page-top")!
  const _component_recipient_sticky_summary = _resolveComponent("recipient-sticky-summary")!
  const _component_side_nav_journey = _resolveComponent("side-nav-journey")!
  const _component_recipient_summary = _resolveComponent("recipient-summary")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.organCode)
      ? (_openBlock(), _createBlock(_component_loading_recipient_page, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, { to: { name: 'list-recipients' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('recipients')), 1)
                ]),
                _: 1
              }),
              _createTextVNode(" / "),
              _createVNode(_component_router_link, {
                to: { name: 'edit-recipient', params: { id: _ctx.recipientId } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.recipientDisplayName), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createTextVNode(" / " + _toDisplayString(_ctx.$t(_ctx.organName)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_recipient_sticky_summary),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_side_nav_journey, {
                  newOrgan: _ctx.organName,
                  organCode: _ctx.organCode
                }, null, 8, ["newOrgan", "organCode"]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_recipient_summary),
                  _createVNode(_component_validation_observer, { ref: "validations" }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.organComponentName), {
                        "new-journey": true,
                        ref: "organComponent"
                      }, null, 512))
                    ]),
                    _: 1
                  }, 512)
                ])
              ])
            ])
          ])
        ]))
  ]))
}